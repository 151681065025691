import { template as template_f2299167e4eb4b67a8dda9297edb1224 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f2299167e4eb4b67a8dda9297edb1224(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
