import { template as template_9929844a661b484e8168b44105d0507c } from "@ember/template-compiler";
const FKLabel = template_9929844a661b484e8168b44105d0507c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
