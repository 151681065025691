import { template as template_df5ba029a3e347f6b85071836e72d3af } from "@ember/template-compiler";
const FKText = template_df5ba029a3e347f6b85071836e72d3af(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
