import { template as template_df45bd6e1f2f4532a987f406c0269f49 } from "@ember/template-compiler";
import { eq } from "truth-helpers";
const FKControlConditionalContentItem = template_df45bd6e1f2f4532a987f406c0269f49(`
  {{#if (eq @name @activeName)}}
    <div class="form-kit__conditional-display-content">
      {{yield}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlConditionalContentItem;
